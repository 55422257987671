<template>
  <Page title="Notification">
    <SectionContainer class="c-notification">
      <BaseButton
        :to="$i18nRoute({name: PROFILE})"
        icon="prev"
        :btn-label="$t('common.ctaLabels.back')"
        color="muted"
        class="c-notification__backButton"
      />

      <div class="h-pv-10">
        <Typography
          v-text="notification.title"
          variant="subtitle1"
        />
        <Typography
          v-text="notification.subtitle"
          variant="body1"
        />
      </div>
      <form
        class="c-notification__content h-mv-10 h-pb-10"
        @submit.prevent="handleSubmit"
      >
        <div class="c-notification__contentGroup">
          <Typography
            class="-foldBold"
            v-text="notification.order.title"
            variant="subtitle2"
          />

          <Checkbox
            name="orderSMS"
            :text="notification.order.viaSMS"
            :checked="form.orderViaSMS"
            v-model="form.orderViaSMS"
          />
          <Checkbox
            name="orderEmail"
            :text="notification.order.viaEmail"
            :checked="form.orderViaEmail"
            v-model="form.orderViaEmail"
          />
        </div>
        <div class="c-notification__contentGroup">
          <Typography
            class="-foldBold"
            v-text="notification.promotions.title"
            variant="subtitle2"
          />

          <Checkbox
            name="promotionSMS"
            :text="notification.promotions.viaSMS"
            v-model="form.promotionViaSMS"
            :checked="form.promotionViaSMS"
          />
          <Checkbox
            name="promotionEmail"
            :text="notification.promotions.viaEmail"
            v-model="form.promotionViaEmail"
            :checked="form.promotionViaEmail"
          />
        </div>
        <div class="c-notification__contentGroup h-pv-20">
          <BaseButton
            :btn-label="notification.saveButtonText"
            variant="solid"
            :is-full-width="isMobile"
            type="submit"
            :disabled="!indexes.length"
          />
        </div>
      </form>
    </SectionContainer>
  </Page>
</template>

<script setup>
import {
  computed, inject, ref, watchEffect,
} from 'vue';
import { useStore } from 'vuex';

import Page from '@/components/Page.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import Checkbox from '@/components/common/Checkbox.vue';

const store = useStore();

const user = computed(() => store.state.auth.user);
const notification = computed(() => store.state.i18n.widgets.profile.notification);
const isMobile = inject('isMobile');
const { PROFILE } = inject('routeNames');
const indexes = ref([]);

const checkNotificationId = (section, id) => {
  const res = user.value.notifications.find(not => not.section === section && not.id === id);

  return Boolean(res);
};

const form = ref({
  orderViaSMS: checkNotificationId('order', 1),
  orderViaEmail: checkNotificationId('order', 2),
  promotionViaSMS: checkNotificationId('promotion', 3),
  promotionViaEmail: checkNotificationId('promotion', 4),
});

/** Check to update ID on *Indexex*  */
const updateIndexes = () => {
  Object.entries(form.value).forEach((arr, index) => {
    const addIdx = index + 1;
    const find = indexes.value.find(i => i === addIdx);
    const findIndex = indexes.value.findIndex(i => i === addIdx);

    if (arr[1] && !find) {
      indexes.value.push(addIdx);
    } else if (!arr[1] && find) {
      indexes.value.splice(findIndex, 1);
    }
  });
};

const handleSubmit = async () => {
  await store.dispatch('auth/updateCustomerNotifications', indexes.value);
};

watchEffect(updateIndexes);

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-notification {
  padding-top: 100px;
  padding-bottom: 135px;

  &__backButton {
    width: auto;

    text-transform: capitalize;

    border-color: var(--#{ v.$variable-prefix}tertiarySoft);

    ::v-deep(svg) {
      position: absolute;
      left: 30px;
    }

    @include mx.bp("tablet-wide") {
      width: max-content;

      border-color: transparent;

      ::v-deep(svg) {
        position: relative;
        left: 0;
      }
    }
  }

  &__content {
    &Group {
      margin: 10px 0;

      border-top: 1px solid var(--#{ v.$variable-prefix}grayWarm);
    }

    .-flexContent {
      @include mx.d-flex(false, center);
    }

    @include mx.bp("tablet-wide") {
      width: 70%;
    }
  }
}

</style>
